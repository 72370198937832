import React, {PureComponent, useState} from 'react';
import {PieChart, Pie, Sector, ResponsiveContainer} from 'recharts';

const data = [
    {name: '1 Gün', value: 400,fill: "#F67280"},
    {name: '2 Gün', value: 300,fill: "#C06C84"},
    {name: '3 Gün', value: 300,fill: "#a85c9f"},
    {name: '4-6 Gün', value: 300,fill: "#6C5B7B"},
    {name: '7+ Gün', value: 200,fill: "#355C7D"},
];

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Toplam ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Oran ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

export default function ShippingTrackerPieChart({data}) {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <ResponsiveContainer width="100%" height={270}>
            <PieChart width={400} height={400}>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill="#ffc30f"
                    dataKey="value"
                    onMouseEnter={(data, index) => {
                        setActiveIndex(index);
                    }}
                />
            </PieChart>
        </ResponsiveContainer>
    );
}
